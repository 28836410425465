<style scoped>
/* /deep/ .el-autocomplete-suggestion {
  width: auto!important;
}
.el-select > .el-select-dropdown {
  transform:translateX(-100px);
} */
</style>

<template>
  <div class="vue-box">
    <!-- 参数栏 -->
    <div class="c-panel">
      <div class="c-title">主体信息</div>
      <el-row type="flex" justify="left" align="middle">
        <el-form label-width="200px" :model="subject" :rules="rules">

          <el-form-item label="选择直付通服务商:" placeholder="请选择">
            <el-select v-model="subject.appid" @click.native="getZft()">
              <el-option v-for="item in zftlist" :key="item.appid" :label="item.mchName" :value="item.appid" />
            </el-select>
          </el-form-item>

          <el-form-item label="法人身份证号码:" >
            <el-input style="width: 350px" v-model="subject.idNumber" placeholder="请填写个人身份证号码"></el-input>
          </el-form-item>

          <el-form-item label="商户简称:" >
            <el-input style="width: 350px" v-model="subject.aliasName" placeholder="请填写商户简称"></el-input>
          </el-form-item>

          <el-form-item label="选择经营类目:">
            <el-cascader v-model="subject.mcc" :options="options.data" @change="handleChange"></el-cascader>
          </el-form-item>

          <el-form-item label="营业执照">
            <el-upload class="upload-demo" drag :data="subject.industryQualificationImage" :action="this.sa.cfg.api_url + '/v1/index/alipayImg/'+ this.subject.appid" :on-success="handleImgIdSuccess">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </el-form-item>
          <el-alert type="info" description="营业执照经营范围包含网络游戏制作、经营、销售、系统服务、技术、计算机、通讯设备、电子产品、互联网、网络技术、网络工程等任意一项业务" show-icon>
            </el-alert>
        </el-form>
      </el-row>
    </div>

    <div class="c-panel">
      <div class="c-title">经营信息</div>
      <el-row type="flex" justify="left" align="middle">
        <el-form label-width="200px" :model="business" :rules="rules">
          <el-form-item label="WAP站点名称:" >
            <el-input style="width: 350px" v-model="business.wapName" placeholder="请填站点名称"></el-input>
          </el-form-item>

          <el-form-item label="WAP站点链接:" >
            <el-input style="width: 350px" v-model="business.wapUrl" placeholder="请填写WAP站点链接"></el-input>
          </el-form-item>

          <el-form-item label="联系人姓名:" >
            <el-input style="width: 350px" v-model="business.name" placeholder="请填写联系人姓名"></el-input>
          </el-form-item>

          <el-form-item label="联系人手机号:" >
            <el-input style="width: 350px" v-model="business.phone" placeholder="请填写联系人手机号"></el-input>
          </el-form-item>

          <el-form-item label="联系人邮箱:" >
            <el-input style="width: 350px" v-model="business.email" placeholder="请填写联系人邮箱"></el-input>
          </el-form-item>

          <el-form-item label="签约支付宝号:" >
            <el-input style="width: 350px" v-model="business.bindingAlipay" placeholder="请填写签约支付宝号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-alert type="info" description="注：签约支付宝号必须已实名认证" show-icon>
            </el-alert>
          </el-form-item>
        </el-form>
      </el-row>
    </div>

    <div class="c-panel">
      <div class="c-title">经营信息</div>
      <el-row type="flex" justify="left" align="middle">
        <el-form label-width="200px" :model="settle" :rules="rules">
          <el-form-item label="结算支付宝账号:" >
            <el-input style="width: 350px" v-model="settle.alipay" placeholder="请填结算支付宝账号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-alert type="info" description="注：账号主体名称需与身份证同名" show-icon>
            </el-alert>
          </el-form-item>
        </el-form>
      </el-row>
    </div>

    <div class="c-panel">
      <div class="c-title">提交审核</div>
      <el-row type="flex" justify="left" align="middle">
        <el-form label-width="200px">

          <el-form-item>
            <span class="c-label">
              <el-button type="primary" @click="submit()">确认提交</el-button></span>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import mockData from './data-list.js';
export default {
  data() {
    return {
      options: mockData,
      subject: {
        idNumber: '',
        aliasName: '',
        mcc: '',
        appid: '',
        industryQualificationImage: '',
        industryQualificationType:'3500'
      },
      business: {
        wapName: '',
        wapUrl: '',
        phone: '',
        email: '',
        name: '',
        bindingAlipay: '',
      },
      settle: {
        alipay: ''
      },
      zftlist: [],
      rules: {
        idNumber: [
          { required: true, message: '个人身份证必须添加', trigger: 'blur' },
          { min: 18, max: 18, message: '确保身份证的长度为18', trigger: 'blur' }
        ],
        aliasName: [
          { required: true, message: '请填写商户简称', trigger: 'blur' }
        ],
        wapName: [
          { required: true, message: '请填WAP站点名称', trigger: 'blur' }
        ],
        wapUrl: [
          { required: true, message: '请填写站点链接', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请填写联系人手机号', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请填写联系人邮箱', trigger: 'blur' }
        ],
        bindingAlipay: [
          { required: true, message: '请填写签约支付宝号:', trigger: 'blur' }
        ],
        alipay: [
          { required: true, message: '请填写结算支付宝账号:', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    ok: function () {
    },
    handleChange(value) {
      console.log(value);
      this.subject.mcc = value[1];
    },
    submit() {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      let p = { ...this.subject, ...this.business, ...this.settle };
      this.sa.ajax(
        "/v1/alipay/merchant/simplecreate", p,
        function (res) {
          console.log(res)
          this.sa.ok("提交成功");
        }.bind(this),
        defaultCfg
      );
    },
    handleImgIdSuccess: function (res)
    {
      this.subject.industryQualificationImage = res.data;
      console.log(this.subject.industryQualificationImage);

    },
    getZft: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      };
      var p = {
        page: 0,
        type: [1],
        payType: [0],
        limit: 30,
      };
      this.sa.ajax(
        "/v1/alipay/config/query", p,
        function (res) {
          this.zftlist = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
  },
  created() {
    // this.getZft();
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>


